import { LoadingArea } from '@consigli/facade';
import { useGetBlobQuery, useProjectId, useReadUrl } from '@consigli/hooks';
import { Document, DocumentType } from '@consigli/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PDFViewer } from '@/molecules/pdf-viewer/pdf-viewer';

import { isDocumentDoubleViewerCapable } from './fullscreen/finding/container';

interface DoublePdfViewerProps {
  document: Document;
  isPreview: boolean;
}

export const DoublePdfViewer: FC<DoublePdfViewerProps> = ({ document, isPreview }) => {
  const { t } = useTranslation();

  if (!isDocumentDoubleViewerCapable(document)) {
    throw new Error(`Opened double viewer without refBlobId and refContent!`);
  }
  const projectId = useProjectId();
  if (
    document.type === DocumentType.FINDING &&
    (document.id == null || document.refBlobId == null)
  ) {
    throw new Error('Cannot open Double PDF viewer with missing Blob IDs!');
  }
  const { readUrl: primaryReadUrl } = useReadUrl(document);
  const { readUrl: secondaryReadUrl } = useReadUrl(document, true);

  const { data: refBlob } = useGetBlobQuery({
    projectId: projectId,
    packageId: document.refDocumentPackageId || '',
    blobId: document.refBlobId || '',
  });

  if (primaryReadUrl == null || secondaryReadUrl == null) {
    return <LoadingArea />;
  }

  if (isPreview) {
    return (
      <div className="grid grid-flow-row w-full">
        <div className="overflow-hidden px-2">
          <div className="text-sm text-center p-2 truncate">
            {document.name ?? t('error.missing-pdf-name')}
          </div>
          <PDFViewer
            id={1}
            fileUrl={primaryReadUrl}
            documentName={document.name ?? t('error.missing-pdf-name')}
            findingCoordinates={document.findingCoordinates}
            searchTerm={document.content}
            searchFallbackPage={document.pageNumber ?? undefined}
            isPreview={true}
          />
        </div>
        <div className="overflow-hidden px-2">
          <div className="text-sm text-center pb-2 pt-5 truncate">
            {refBlob?.name ?? t('error.missing-pdf-name')}
          </div>
          <PDFViewer
            id={2}
            fileUrl={secondaryReadUrl}
            documentName={refBlob?.name ?? t('error.missing-pdf-name')}
            findingCoordinates={document.refFindingCoordinates}
            searchTerm={document.refContent}
            searchFallbackPage={document.refPageNumber ?? undefined}
            isPreview={true}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="w-1/2 px-2 mb-4 h-[95%]">
        <div className="text-sm text-white text-center p-2">
          {document.name ?? t('error.missing-pdf-name')}
        </div>
        <PDFViewer
          id={1}
          fileUrl={primaryReadUrl}
          documentName={document.name ?? t('error.missing-pdf-name')}
          findingCoordinates={document.findingCoordinates}
          searchTerm={document.content}
          searchFallbackPage={document.pageNumber ?? undefined}
          isPreview={false}
        />
      </div>
      <div className="w-1/2 px-2 mb-4 h-[95%]">
        <div className="text-sm text-white text-center p-2">
          {(refBlob && refBlob.name) ?? t('error.missing-pdf-name')}
        </div>
        <PDFViewer
          id={2}
          fileUrl={secondaryReadUrl}
          documentName={refBlob?.name ?? t('error.missing-pdf-name')}
          findingCoordinates={document.refFindingCoordinates}
          searchTerm={document.refContent}
          searchFallbackPage={document.refPageNumber ?? undefined}
          isPreview={false}
        />
      </div>
    </>
  );
};
